





































import Vue from "vue";
import Component from "vue-class-component";

import { UiState } from "@/UiState";

import { eventLinkFor } from "@/utils/UrlUtils";
import { NotificationService } from "@/services/NotificationService";

@Component({})
export default class NavigationComponent extends Vue {
  uiState: UiState = UiState.getInstance();

  onNavigateToDashboardClicked(): void {
    const curPath = this.$route.path;
    const destPath = "/";

    if (curPath === destPath) return;

    this.$router.push(destPath).catch((err) => {
      NotificationService.getInstance().notifyError(err.message);
    });
  }

  linkFor(id: number): string {
    return eventLinkFor(id);
  }
}
