import Vue from "vue";

class EventBus {
  private readonly _internalBus: Vue;

  private constructor() {
    this._internalBus = new Vue();
  }

  private static _instance: EventBus;

  static getInstance(): EventBus {
    if (!EventBus._instance) {
      EventBus._instance = new EventBus();
    }

    return EventBus._instance;
  }

  /* eslint-disable */
  public on(event: string | string[], callback: Function): EventBus {
    this._internalBus.$on(event, callback);
    return this;
  }
  /* eslint-enable */

  /* eslint-disable */
  public once(event: string | string[], callback: Function): EventBus {
    this._internalBus.$once(event, callback);
    return this;
  }
  /* eslint-enable */

  /* eslint-disable */
  public off(event?: string | string[], callback?: Function): EventBus {
    this._internalBus.$off(event, callback);
    return this;
  }
  /* eslint-enable */

  /* eslint-disable */
  
  public emit(event: string, data : object | null = null): EventBus {
    this._internalBus.$emit(event, data);
    return this;
  }
  
  /* eslint-enable */
}

export { EventBus };
