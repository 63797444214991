
















import Vue from "vue";
import Component from "vue-class-component";

import LogoutComponent from "@/components/LogoutComponent.vue";

import { UiState } from "@/UiState";

@Component({
  components: { LogoutComponent },
})
export default class HeaderComponent extends Vue {
  uiState: UiState = UiState.getInstance();
}
