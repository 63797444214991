import { OCREventDetails } from "@/types/OCRTypes";

function chunkEventDetails(
  chunkSize: number,
  eventDetails: OCREventDetails[]
): OCREventDetails[][] {
  const result: OCREventDetails[][] = [];
  for (let i = 0, j = eventDetails.length; i < j; i += chunkSize) {
    result.push(eventDetails.slice(i, i + chunkSize));
  }

  return result;
}

export { chunkEventDetails };
