





















import Vue from "vue";
import Component from "vue-class-component";

import { Prop } from "vue-property-decorator";

import ComponentContainer from "@/components/util/ComponentContainer.vue";

import VueApexCharts from "vue-apexcharts";
import { ApexOptions } from "apexcharts";
import { ApexAxisChartSeries } from "@/types/CustomUiDataTypes";

@Component({
  components: {
    ComponentContainer,
    VueApexCharts,
  },
})
export default class ChartComponent extends Vue {
  @Prop()
  title!: string;

  @Prop()
  subTitle!: string;

  @Prop()
  chartOptions!: ApexOptions;

  @Prop()
  chartSeries!: ApexAxisChartSeries[];

  onTitleClickedHandler(): void {
    this.$emit("title-clicked");
  }
}
