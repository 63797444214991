













import Vue from "vue";
import Component from "vue-class-component";
import RunsTable from "@/components/util/RunsTable.vue";

import { Prop } from "vue-property-decorator";

import { OCREventDetails, OCRRun } from "@/types/OCRTypes";

import { convertToDate } from "@/utils/FormatUtils";

@Component({
  components: {
    RunsTable,
  },
})
export default class EventComponent extends Vue {
  @Prop()
  eventDetails!: OCREventDetails;

  @Prop()
  titleClickable!: boolean;

  get title(): string {
    return (
      this.eventDetails.name +
      " - " +
      convertToDate(this.eventDetails.dateOfRun)
    );
  }

  get subTitle(): string {
    const registrations = [];

    for (let registrationPhase of this.eventDetails.registrations) {
      const fromDate = convertToDate(registrationPhase.begin);
      const toDate = convertToDate(registrationPhase.end);

      registrations.push(`${fromDate} - ${toDate}`);
    }

    if (registrations.length === 0) return "";

    let label: string;
    let value: string;

    if (registrations.length === 1) {
      label = this.$t("ocr.run.table.registrationPhase") as string;
      value = registrations[0];
    } else {
      label = this.$t("ocr.run.table.registrations") as string;
      const separator = this.$t(
        "ocr.run.table.registrationsSeparator"
      ) as string;
      value = registrations.join(separator);
    }

    return `${label} ${value}`;
  }

  onRunClickedHandler(eventValue: { run: OCRRun; field: string }): void {
    this.$emit("run-clicked", {
      eventDetails: this.eventDetails,
      run: eventValue.run,
      field: eventValue.field,
    });
  }

  onSummaryClickedHandler(eventValue: { field: string }): void {
    this.$emit("summary-clicked", {
      eventDetails: this.eventDetails,
      field: eventValue.field,
    });
  }

  onComponentContainerClickedHandler(): void {
    this.$emit("component-container-clicked", this.eventDetails);
  }
}
