import { SecurityService } from "@/services/SecurityService";
import { ApplicationService } from "@/services/ApplicationService";
import Axios, { AxiosInstance, AxiosResponse } from "axios";
import { Page, PaginationQuery } from "@/types/RemoteTypes";

import {
  OCRSeries,
  OCREvent,
  OCRChartType,
  OCRChartData,
  OCREventDetails,
  OCRParticipant,
  OCREventStatus,
  OCRParticipantSearch,
  OCRParticipantStatus,
  OCRTicket,
} from "@/types/OCRTypes";

class OCRServiceProvider {
  private _service: OCRService;

  private constructor() {
    this._service = new RemoteOCRService();
  }

  private static _instance: OCRServiceProvider;

  static getInstance(): OCRServiceProvider {
    if (!OCRServiceProvider._instance) {
      OCRServiceProvider._instance = new OCRServiceProvider();
    }

    return OCRServiceProvider._instance;
  }

  getService(): OCRService {
    return this._service;
  }
}

interface OCRService {
  getSeries(): Promise<OCRSeries>;
  getEvent(eventId: number): Promise<OCREventDetails>;
  getEvents(): Promise<OCREvent[]>;
  getSeriesChartData(chartType: OCRChartType): Promise<OCRChartData>;
  getEventChartData(
    eventId: number,
    chartType: OCRChartType
  ): Promise<OCRChartData>;
  getEventDetails(): Promise<OCREventDetails[]>;
  findParticipants(
    eventId: number,
    search: OCRParticipantSearch,
    paginationQuery: PaginationQuery
  ): Promise<Page<OCRParticipant>>;
  getTicket(ticketId: number): Promise<OCRTicket>;

  downloadParticipants(
    eventId: number,
    search: OCRParticipantSearch
  ): Promise<Blob>;
  uploadParticipants(eventId: number, file: File): Promise<any>;
  downloadTicket(ticketId: number): Promise<Blob>;
  sendMailToTicket(ticketId: number): Promise<any>;
}

class RemoteOCRService implements OCRService {
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = Axios.create({
      url: ApplicationService.API_URL,
      baseURL: ApplicationService.API_URL,
    });

    this.applySecurityConfig();
  }

  private applySecurityConfig() {
    this.axiosInstance.interceptors.request.use(
      (config) =>
        new Promise((resolve, reject) => {
          SecurityService.getToken().then((token) => {
            config.headers.Authorization = "Bearer " + token;
            resolve(config);
          });
        })
    );
  }
  getSeries(): Promise<OCRSeries> {
    return this.axiosInstance.get("/series").then(this.getSeriesConverter);
  }

  private getSeriesConverter(response: AxiosResponse<any>): OCRSeries {
    return response.data;
  }

  getEvent(eventId: number): Promise<OCREventDetails> {
    return this.axiosInstance
      .get("/events/" + eventId)
      .then(this.getEventConverter);
  }

  private getEventConverter(response: AxiosResponse<any>): OCREventDetails {
    const data = response.data;
    if (data === undefined || data === null) throw new Error("404");

    return data;
  }

  getEvents(): Promise<OCREvent[]> {
    return this.axiosInstance
      .get("/events?page=0&size=100")
      .then(this.getEventsConverter);
  }

  private getEventsConverter(response: AxiosResponse<any>): OCREvent[] {
    const result: OCREvent[] = [];

    if (response.data === undefined || response.data === null) return result;
    if (response.data.data === undefined || response.data.data === null)
      return result;

    const events: Array<OCREvent> = response.data.data;
    for (const ocrEvent of events) {
      if (ocrEvent.status === OCREventStatus.ARCHIVED) continue;

      result.push(ocrEvent);
    }

    return result;
  }

  getSeriesChartData(chartType: OCRChartType): Promise<OCRChartData> {
    return this.axiosInstance
      .get("/series/chartdata/" + this.chartTypeAsApiString(chartType))
      .then(this.getSeriesChartDataConverter);
  }

  private chartTypeAsApiString(chartType: OCRChartType): string {
    switch (chartType) {
      case OCRChartType.Registrations:
        return "REGISTRATIONS";
      case OCRChartType.NetSales:
        return "NET_SALES";
      case OCRChartType.PaidNotConfirmed:
        return "PAID_NOT_CONFIRMED";
      case OCRChartType.PaidAndConfirmed:
        return "PAID_AND_CONFIRMED";
      default:
        throw new Error("Invalid ChartType");
    }
  }

  private getSeriesChartDataConverter(
    response: AxiosResponse<any>
  ): OCRChartData {
    return response.data;
  }

  getEventChartData(
    eventId: number,
    chartType: OCRChartType
  ): Promise<OCRChartData> {
    return this.axiosInstance
      .get(
        "/events/" +
          eventId +
          "/chartdata/" +
          this.chartTypeAsApiString(chartType)
      )
      .then(this.getEventChartDataConverter);
  }

  private getEventChartDataConverter(
    response: AxiosResponse<any>
  ): OCRChartData {
    return response.data;
  }

  getEventDetails(): Promise<OCREventDetails[]> {
    return this.axiosInstance
      .get("/events/detailed?page=0&size=100")
      .then(this.getEventDetailsConverter);
  }

  private getEventDetailsConverter(
    response: AxiosResponse<any>
  ): OCREventDetails[] {
    return response.data.data;
  }

  findParticipants(
    eventId: number,
    search: OCRParticipantSearch,
    paginationQuery: PaginationQuery
  ): Promise<Page<OCRParticipant>> {
    const searchQueryString = this.createParticipantSearchQuery(search);
    const paginationQueryString = paginationQuery.asQueryString(false);

    return this.axiosInstance
      .get(
        "/events/" +
          eventId +
          "/tickets" +
          paginationQueryString +
          searchQueryString
      )
      .then(this.findParticipantsConverter);
  }

  private createParticipantSearchQuery(search: OCRParticipantSearch): string {
    let searchQueryString = "";

    if (search.lastName !== null && search.lastName.length > 0)
      searchQueryString += this.createQuery("lastName", search.lastName);
    if (search.firstName !== null && search.firstName.length > 0)
      searchQueryString += this.createQuery("firstName", search.firstName);
    if (search.email !== null && search.email.length > 0)
      searchQueryString += this.createQuery("email", search.email);
    if (search.ticketCode !== null && search.ticketCode.length > 0)
      searchQueryString += this.createQuery("ticketCode", search.ticketCode);

    if (search.status !== undefined && search.status !== null) {
      if (OCRParticipantStatus.PAID_NOT_CONFIRMED === search.status) {
        searchQueryString += this.createQuery("status", "NEW");
      } else if (OCRParticipantStatus.PAID_AND_CONFIRMED === search.status) {
        searchQueryString += this.createQuery("status", "COMPLETED");
      }
    }

    if (search.eventItem !== null && search.eventItem.length > 0)
      searchQueryString += this.createQuery("eventItem", search.eventItem);

    if (search.teamname !== null && search.teamname.length > 0)
      searchQueryString += this.createQuery("teamname", search.teamname);

    return searchQueryString;
  }

  private createQuery(param: string, value: string): string {
    return `&${param}=${encodeURIComponent(value)}`;
  }

  private findParticipantsConverter(
    response: AxiosResponse<any>
  ): Page<OCRParticipant> {
    const pageResult = response.data;

    const participantsData = pageResult.data;
    const participants = RemoteOCRService.convertParticipants(participantsData);
    return {
      elements: participants,
      totalPages: pageResult.totalPages,
      totalElements: pageResult.totalSize,
      number: pageResult.page,
      size: pageResult.size,
      first: pageResult.page === 0,
      last: !pageResult.hasNext,
      next: pageResult.hasNext,
      previous: !pageResult.result,
    };
  }

  private static convertParticipants(
    rawData: Array<any>
  ): Array<OCRParticipant> {
    const result: Array<OCRParticipant> = [];

    for (const obj of rawData) {
      result.push({
        created: obj.created,
        id: obj.id,
        name: obj.userModel.lastName,
        foreName: obj.userModel.firstName,
        runName: obj.eventItemName,
        email: obj.userModel.email,
        shirtSize: obj.tshirt,
        gender: obj.userModel.sex[0],
        dayOfBirth: obj.userModel.dayOfBirth,
        runStart: obj.startTime,
        liabilityConsent: obj.disclaimer,
        termsAndConditionsConsent: obj.conditionOfParticipation,
        generalTermsAndConditionsConsent: obj.generalTermsAndConditions,
        ticketNumber: obj.ticketCode,
        teamName: obj.teamname,
        teamCaptain: obj.captain,
        status: obj.status,
        startNr: obj.startNo === null ? "" : obj.startNo,
      });
    }

    return result;
  }

  getTicket(ticketId: number): Promise<OCRTicket> {
    return this.axiosInstance
      .get("/tickets/" + ticketId + "/detailed")
      .then(this.getTicketConverter);
  }

  private getTicketConverter(response: AxiosResponse<any>): OCRTicket {
    const data = response.data;
    if (data === undefined || data === null) throw new Error("404");

    return data;
  }

  downloadParticipants(
    eventId: number,
    search: OCRParticipantSearch
  ): Promise<Blob> {
    let searchQueryString = this.createParticipantSearchQuery(search);
    if (searchQueryString.length > 0) {
      searchQueryString = "?" + searchQueryString;
    }

    return this.axiosInstance
      .get("/events/" + eventId + "/ticketExport" + searchQueryString, {
        responseType: "blob",
      })
      .then((response) => {
        return new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument. spreadsheetml.sheet ",
        });
      });
  }

  uploadParticipants(eventId: number, file: File): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);

    return this.axiosInstance.post("/events/" + eventId, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  downloadTicket(ticketId: number): Promise<Blob> {
    return this.axiosInstance
      .get("/tickets/" + ticketId + "/pdf", {
        responseType: "blob",
      })
      .then((response) => {
        return new Blob([response.data], {
          type: "application/pdf",
        });
      });
  }

  sendMailToTicket(ticketId: number): Promise<any> {
    return this.axiosInstance.post("/tickets/" + ticketId + "/sendEmail");
  }
}

export { OCRServiceProvider, OCRService };
