import { DateTimeFormatOptions } from "vue-i18n";

const LOCALE = "de-DE";

function dateFormatOptions(): DateTimeFormatOptions {
  return { day: "2-digit", month: "2-digit", year: "numeric" };
}

function dateTimeFormatOptions(): DateTimeFormatOptions {
  return {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
}

function convertToDate(dateStr: string): string {
  const date = new Date(dateStr);
  return date.toLocaleDateString(LOCALE, dateFormatOptions());
}

function convertToDateTime(dateStr: string): string {
  const date = new Date(dateStr);
  return date.toLocaleString(LOCALE, dateTimeFormatOptions());
}

export { convertToDate, convertToDateTime };
