import {
  OCRSeries,
  OCREvent,
  OCREventDetails,
  OCRTicket,
} from "@/types/OCRTypes";
import { chunkEventDetails } from "@/utils/ChunkUtil";

class UiState {
  private _navigationMinimized: boolean;

  private _ocrSeries: OCRSeries | null;
  private _ocrEvents: OCREvent[];

  private _rawOcrEventDetails: OCREventDetails[];
  private _chunkedOcrEventDetails: OCREventDetails[][];

  private _ocrEvent: OCREventDetails | null;
  private _ocrEventRuns: Array<string>;

  private _ocrTicket: OCRTicket | null;

  private constructor() {
    this._navigationMinimized = false;
    this._ocrSeries = null;
    this._ocrEvents = [];

    this._rawOcrEventDetails = [];
    this._chunkedOcrEventDetails = [];

    this._ocrEvent = null;
    this._ocrEventRuns = [];

    this._ocrTicket = null;
  }

  private static _instance: UiState;

  static getInstance(): UiState {
    if (!UiState._instance) {
      UiState._instance = new UiState();
    }

    return UiState._instance;
  }

  get navigationMinimized(): boolean {
    return this._navigationMinimized;
  }

  set navigationMinimized(value: boolean) {
    this._navigationMinimized = value;
    this.updateChunkedOcrEventDetails();
  }

  toggleNavigationMinimized(): void {
    this.navigationMinimized = !this.navigationMinimized;
  }

  get ocrSeries(): OCRSeries {
    return this._ocrSeries!;
  }

  set ocrSeries(value: OCRSeries) {
    this._ocrSeries = value;
  }

  get ocrEvents(): OCREvent[] {
    return this._ocrEvents;
  }

  set ocrEvents(value: OCREvent[]) {
    this._ocrEvents = value;
  }

  get rawOcrEventDetails(): OCREventDetails[] {
    return this._rawOcrEventDetails;
  }

  set rawOcrEventDetails(value: OCREventDetails[]) {
    this._rawOcrEventDetails = value;
    this.updateChunkedOcrEventDetails();
  }

  get chunkedOcrEventDetail(): OCREventDetails[][] {
    return this._chunkedOcrEventDetails;
  }

  get ocrEvent(): OCREventDetails {
    return this._ocrEvent!;
  }

  set ocrEvent(value: OCREventDetails) {
    this._ocrEvent = value;
    this.extractOCREventRuns(value);
  }

  get ocrEventRuns(): Array<string> {
    return this._ocrEventRuns;
  }

  private updateChunkedOcrEventDetails(): void {
    const chunkSize = this.navigationMinimized ? 2 : 1;
    this._chunkedOcrEventDetails = chunkEventDetails(
      chunkSize,
      this.rawOcrEventDetails
    );
  }

  private extractOCREventRuns(value: OCREventDetails) {
    this._ocrEventRuns = [];
    if (value !== undefined && value !== null) {
      for (const run of value.runData.runs) {
        this._ocrEventRuns.push(run.name);
      }
    }
  }

  get ocrTicket(): OCRTicket {
    return this._ocrTicket!;
  }

  set ocrTicket(value: OCRTicket) {
    this._ocrTicket = value;
  }
}

export { UiState };
