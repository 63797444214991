import { ChartValueType } from "@/types/ChartTypes";

interface OCRSeries {
  runData: OCRRunData;
}

enum OCREventStatus {
  PLANNED = "PLANNED",
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

interface OCREvent {
  id: number;
  name: string;
  status: OCREventStatus;
}

interface OCREventDetails extends OCREvent {
  dateOfRun: string;
  registrations: OCRRegistrationPhase[];
  runData: OCRRunData;
}

interface OCRRegistrationPhase {
  begin: string;
  end: string;
}

interface OCRRunData {
  runs: OCRRun[];
  summary: OCRRun;
}

interface OCRRun {
  name: string;
  registrations: number;
  paidNotConfirmed: number;
  paidAndConfirmed: number;
  limit: number;
  free: [number, number];
  netSales: [number, number];
}

enum OCRChartType {
  Registrations,
  NetSales,
  PaidNotConfirmed,
  PaidAndConfirmed,
}

function asChartValueType(ocrChartType: OCRChartType): ChartValueType {
  switch (ocrChartType) {
    case OCRChartType.Registrations:
    case OCRChartType.PaidNotConfirmed:
    case OCRChartType.PaidAndConfirmed:
      return ChartValueType.Number;
    case OCRChartType.NetSales:
      return ChartValueType.Monetary;
    default:
      return ChartValueType.Unspecified;
  }
}

interface OCRChartData {
  categories: string[];
  values: { name: string; data: number[] }[];
}

interface OCRParticipant {
  created: string;
  id: number;
  name: string;
  foreName: string;
  runName: string;
  email: string;
  shirtSize: string;
  gender: string;
  dayOfBirth: string;
  runStart: string;
  teamName: string;
  teamCaptain: string;
  liabilityConsent: boolean;
  termsAndConditionsConsent: boolean;
  generalTermsAndConditionsConsent: boolean;
  startNr: string;
  ticketNumber: string;
  status: OCRParticipantStatus;
}

enum OCRParticipantStatus {
  NONE,
  PAID_NOT_CONFIRMED,
  PAID_AND_CONFIRMED,
}

enum OCRTicketStatus {
  NEW = "NEW",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

interface OCRParticipantSearch {
  lastName: string;
  firstName: string;
  email: string;
  ticketCode: string;
  status: OCRParticipantStatus;
  eventItem: string;
  teamname: string;
}

interface OCRTicket {
  order: OCRTicketOrder;
  user: OCRTicketUser;
  currentTicket: OCRTicketItem;
  history: OCRTicketItem[];
}

interface OCRTicketOrder {
  orderNo: string;
  orderStatus: string;
  paymentType: string;
  ticketCount: number;
  currency: string;
  total: number;
  taxTotal: number;
  subTotal: number;
  cartDiscount: number;
  paymentDate: Date;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  postcode: string;
  country: string;
  email: string;
  phone: string;
}

interface OCRTicketItem {
  id: number;
  created: Date;
  updated: Date;
  ticketCode: string;
  startTime: {
    hour: number;
    minute: number;
    second: number;
    nano: number;
  };
  startNo: string;
  tshirt: string;
  eventItemName: string;
  teamname: string;
  captain: string;
  registrationLink: string;
  changeLink: string;
  status: OCRTicketStatus;
  completedAt: Date;
  disclaimer: boolean;
  generalTermsAndConditions: boolean;
  conditionOfParticipation: boolean;
  mailSubscription: boolean;
  eventId: number;
  eventName: string;
  orderId: number;
  userModel: OCRTicketUser;
}

interface OCRTicketUser {
  id: number;
  created: Date;
  updated: Date;
  firstName: string;
  lastName: string;
  email: string;
  sex: string;
  dayOfBirth: string;
}

export {
  OCRSeries,
  OCREventStatus,
  OCREvent,
  OCREventDetails,
  OCRRunData,
  OCRRun,
  OCRRegistrationPhase,
  OCRChartType,
  OCRChartData,
  OCRParticipant,
  OCRParticipantStatus,
  OCRParticipantSearch,
  OCRTicket,
  OCRTicketStatus,
  OCRTicketOrder,
  OCRTicketUser,
  OCRTicketItem,
  asChartValueType,
};
