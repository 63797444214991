import { EventBus } from "@/types/EventBus";
import debounce from "lodash/debounce";

import { AlertType } from "@/types/VuetifyTypes";
import {
  USER_INFORMATION_RESET_EVENT,
  USER_INFORMATION_EVENT,
  LOADING_INFORMATION_EVENT,
  LOADING_INFORMATION_RESET_EVENT,
} from "@/types/EventDefinitions";

const NOTIFICATION_DEBOUNCE_DELAY = 250;

class NotificationService {
  private _eventBus: EventBus;

  private constructor() {
    this._eventBus = EventBus.getInstance();
  }

  private static _instance: NotificationService;

  static getInstance(): NotificationService {
    if (!NotificationService._instance) {
      NotificationService._instance = new NotificationService();
    }

    return NotificationService._instance;
  }

  indicateLoading<T>(
    promise: Promise<T>,
    loadingText: string,
    rejectError = false,
    resetNotifications = true
  ): Promise<T> {
    if (resetNotifications) {
      this._eventBus.emit(USER_INFORMATION_RESET_EVENT);
    }

    const debouncedLoadingInformation = debounce(() => {
      this._eventBus.emit(LOADING_INFORMATION_EVENT, { message: loadingText });
    }, NOTIFICATION_DEBOUNCE_DELAY);
    debouncedLoadingInformation();

    return new Promise<T>((resolve, reject) => {
      promise
        .then((value) => resolve(value))
        .catch((error) => {
          this.notifyError(this.createErrorMessage(error));

          if (rejectError) {
            reject(error);
          }
        })
        .finally(() => {
          debouncedLoadingInformation.cancel();
          this._eventBus.emit(LOADING_INFORMATION_RESET_EVENT);
        });
    });
  }

  /* eslint-disable */
  private createErrorMessage(error: any): string {
    return error as string;
  }
  /* eslint-enable */

  notifyError(text: string): void {
    this.notify(text, "error");
  }

  notifySuccess(text: string): void {
    this.notify(text, "success");
  }

  private notify(text: string, type: AlertType): void {
    this._eventBus.emit(USER_INFORMATION_RESET_EVENT);
    this._eventBus.emit(USER_INFORMATION_EVENT, {
      type: type,
      message: text,
    });
  }
}

export { NotificationService };
