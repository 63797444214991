



















































































































































import Vue from "vue";
import Component from "vue-class-component";

import { Prop } from "vue-property-decorator";

import ComponentContainer from "@/components/util/ComponentContainer.vue";

import { OCRRun } from "@/types/OCRTypes";

@Component({
  components: {
    ComponentContainer,
  },
})
export default class RunsTable extends Vue {
  @Prop()
  title!: string;

  @Prop()
  titleClickable!: boolean;

  @Prop()
  subTitle!: string;

  @Prop()
  items!: OCRRun[];

  @Prop()
  summary!: OCRRun;

  private readonly monetaryFormatter = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  private readonly numberFormatter = Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  formatMonetaryValue(amount: number): string {
    return this.monetaryFormatter.format(amount);
  }

  formatNumberValue(value: number): string {
    return this.numberFormatter.format(value);
  }

  onRunClickedHandler(run: OCRRun, field: string): void {
    this.$emit("run-clicked", {
      run: run,
      field: field,
    });
  }

  onSummaryClickedHandler(field: string): void {
    this.$emit("summary-clicked", {
      field: field,
    });
  }

  onComponentContainerClickedHandler(): void {
    this.$emit("component-container-clicked");
  }
}
