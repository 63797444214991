import Axios from "axios";

class InternalApplicationService {
  private _apiUrl = "";

  async init(): Promise<void> {
    const value = (await Axios.get("/settings.json")).data;

    this._apiUrl = value.API_URL;
  }

  get API_URL(): string {
    return this._apiUrl;
  }
}

export const ApplicationService = new InternalApplicationService();
