import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import { Position, PositionResult } from "vue-router/types/router";
import DashboardView from "../views/DashboardView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Dashboard",
    component: DashboardView,
  },
  {
    path: "/event/:eventId",
    name: "Event",
    component: () =>
      import(/* webpackChunkName: "eventView" */ "../views/EventView.vue"),
  },
  {
    path: "/ticket/:ticketId",
    name: "Ticket",
    component: () =>
      import(/* webpackChunkName: "ticketView" */ "../views/TicketView.vue"),
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () =>
      import(
        /* webpackChunkName: "pageNotFound" */ "../views/PageNotFoundView.vue"
      ),
  },
];

function scrollBehavior(
  to: Route,
  from: Route,
  savedPosition: Position | void
) {
  return { x: 0, y: 0, behavior: "smooth" };
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: scrollBehavior,
});

export default router;
