




import Vue from "vue";
import Component from "vue-class-component";
import DashboardViewComponent from "../components/dashboard/DashboardViewComponent.vue";

import { UiState } from "@/UiState";
import { OCRServiceProvider } from "@/services/remote/OcrService";
import { NotificationService } from "@/services/NotificationService";

@Component({
  components: {
    DashboardViewComponent,
  },
})
export default class DashboardView extends Vue {
  showView = false;

  uiState: UiState = UiState.getInstance();

  loadDataFromApi(): Promise<void> {
    const ocrService = OCRServiceProvider.getInstance().getService();

    return ocrService
      .getSeries()
      .then((series) => {
        this.uiState.ocrSeries = series;
      })
      .then(() => ocrService.getEvents())
      .then((events) => {
        this.uiState.ocrEvents = events;
      })
      .then(() => ocrService.getEventDetails())
      .then((eventDetails) => {
        this.uiState.rawOcrEventDetails = eventDetails;
      });
  }

  loadData(): void {
    const notificationService = NotificationService.getInstance();
    notificationService
      .indicateLoading(
        this.loadDataFromApi(),
        this.$t("messages.loadingData") as string
      )
      .then(() => {
        this.showView = true;
      })
      .catch((err: Error) => {
        this.showView = false;
        notificationService.notifyError(err.message);
      });
  }

  mounted(): void {
    this.loadData();
  }
}
