






















import Vue from "vue";
import Component from "vue-class-component";

import { mdiRefresh } from "@mdi/js";

import { SecurityService } from "@/services/SecurityService";

@Component({})
export default class LogoutComponent extends Vue {
  showRefreshButton = false;
  refreshIcon = mdiRefresh;

  private sessionTimeOut = 0;

  get timeOut(): string {
    const minutes = Math.floor(this.sessionTimeOut / 60);
    const seconds = this.sessionTimeOut % 60;

    let result = "";
    if (minutes < 10) result += "0";
    result += minutes;

    result += ":";

    if (seconds < 10) result += "0";
    result += seconds;

    return result;
  }

  private updateTimeoutValue(): void {
    this.sessionTimeOut = SecurityService.sessionValidInSeconds;
  }

  async refreshSession() {
    await SecurityService.refreshSession();
  }

  async logout() {
    await SecurityService.logout();
  }

  mounted(): void {
    setInterval(() => {
      this.updateTimeoutValue();
    }, 1000);
  }
}
