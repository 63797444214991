











import Vue from "vue";
import Component from "vue-class-component";

import RunsTable from "@/components/util/RunsTable.vue";

import { UiState } from "@/UiState";
import { OCRRun } from "@/types/OCRTypes";

@Component({
  components: {
    RunsTable,
  },
})
export default class SeriesDetailsComponent extends Vue {
  uiState: UiState = UiState.getInstance();

  get title(): string {
    return this.$t("ocr.series.label") as string;
  }

  onRunClickedHandler(eventValue: { run: OCRRun; field: string }): void {
    this.$emit("run-clicked", {
      series: this.uiState.ocrSeries,
      run: eventValue.run,
    });
  }

  onComponentContainerClickedHandler(): void {
    this.$emit("component-container-clicked", this.uiState.ocrSeries);
  }
}
