const LOADING_INFORMATION_EVENT = "loadingInformationEvent";
const LOADING_INFORMATION_RESET_EVENT = "loadingInformationResetEvent";

const USER_INFORMATION_EVENT = "userInformationEvent";
const USER_INFORMATION_RESET_EVENT = "userInformationResetEvent";

export {
  LOADING_INFORMATION_EVENT,
  LOADING_INFORMATION_RESET_EVENT,
  USER_INFORMATION_EVENT,
  USER_INFORMATION_RESET_EVENT,
};
