import { ApexOptions } from "apexcharts";
import { ApexAxisChartSeries } from "@/types/CustomUiDataTypes";
import { ChartValueType } from "@/types/ChartTypes";

function createLineChart(
  categories: string[],
  values: { name: string; data: number[] }[],
  chartTalueType: ChartValueType
): [ApexOptions, ApexAxisChartSeries[]] {
  return [
    createLineChartOptions(categories, chartTalueType),
    createChartSeries(values),
  ];
}

function getFormatterForValueType(
  chartValueType: ChartValueType
): Intl.NumberFormat | undefined {
  switch (chartValueType) {
    case ChartValueType.Monetary:
      return Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    case ChartValueType.Number:
      return Intl.NumberFormat("de-DE", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    default:
      return undefined;
  }
}

function createLineChartOptions(
  categories: string[],
  chartTalueType: ChartValueType
): ApexOptions {
  const formatter = getFormatterForValueType(chartTalueType);

  return {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter:
          ChartValueType.Unspecified === chartTalueType
            ? undefined
            : function (value: number, opts?: any): string | string[] {
                return formatter!.format(value);
              },
      },
    },
    stroke: {
      show: true,
      curve: "straight",
      width: 2,
    },
  };
}

function createChartSeries(
  values: { name: string; data: number[] }[]
): ApexAxisChartSeries[] {
  const result = [];

  for (const value of values) {
    result.push({
      name: value.name,
      data: value.data,
    });
  }

  return result;
}

export { createLineChart, ChartValueType };
