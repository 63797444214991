



































import Vue from "vue";
import Component from "vue-class-component";

import { Prop } from "vue-property-decorator";

@Component({})
export default class ComponentContainer extends Vue {
  @Prop()
  title!: string;

  @Prop()
  subTitle!: string;

  @Prop()
  titleClickable!: boolean;

  onTitleClickedHandler(): void {
    this.$emit("title-clicked");
  }

  onComponentContainerClickedHandler(): void {
    this.$emit("component-container-clicked");
  }
}
