





















import Vue from "vue";
import Component from "vue-class-component";

import SeriesDetailsComponent from "./SeriesDetailsComponent.vue";
import TimeSeriesComponent from "./TimeSeriesComponent.vue";
import EventComponent from "@/components/util/EventComponent.vue";

import { UiState } from "@/UiState";
import { OCREventDetails, OCRRun } from "@/types/OCRTypes";

import { eventLinkFor } from "@/utils/UrlUtils";
import { NotificationService } from "@/services/NotificationService";

@Component({
  components: {
    SeriesDetailsComponent,
    TimeSeriesComponent,
    EventComponent,
  },
})
export default class DashboardViewComponent extends Vue {
  uiState: UiState = UiState.getInstance();

  onEventComponentRunClickedHandler(eventValue: {
    eventDetails: OCREventDetails;
    run: OCRRun;
  }): void {
    this.navigateToEvent(eventValue.eventDetails);
  }

  onEventComponentComponentContainerClickedHandler(
    eventDetails: OCREventDetails
  ): void {
    this.navigateToEvent(eventDetails);
  }

  private navigateToEvent(eventDetails: OCREventDetails): void {
    const curPath = this.$route.path;
    const destPath = eventLinkFor(eventDetails.id);

    if (curPath === destPath) return;

    this.$router.push(destPath).catch((err) => {
      NotificationService.getInstance().notifyError(err.message);
    });
  }
}
