












import { AlertType } from "@/types/VuetifyTypes";
import { UserInformation } from "@/types/CustomUiDataTypes";
import { EventBus } from "@/types/EventBus";
import {
  USER_INFORMATION_EVENT,
  USER_INFORMATION_RESET_EVENT,
} from "@/types/EventDefinitions";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {},
})
export default class UserNotificationComponent extends Vue {
  isRegistered = false;
  isVisible = false;

  isDismissible = true;
  isProminent = false;

  type: AlertType = "success";
  message: string | null = null;

  onUserInformationEvent(evt: UserInformation): void {
    this.message = evt.message;
    this.type = evt.type;

    this.$vuetify.goTo(0);

    this.isVisible = true;
  }

  onUserInformationResetEvent(): void {
    this.message = null;
    this.isVisible = false;
  }

  mounted(): void {
    if (this.isRegistered) return;

    EventBus.getInstance().on(
      USER_INFORMATION_EVENT,
      (evt: UserInformation) => {
        this.onUserInformationEvent(evt);
      }
    );

    EventBus.getInstance().on(USER_INFORMATION_RESET_EVENT, () => {
      this.onUserInformationResetEvent();
    });

    this.isRegistered = true;
  }
}
