import Keycloak, { KeycloakInstance, KeycloakProfile } from "keycloak-js";

const MIN_VALIDITY = 5 * 60;

function getProtocolHostNameAndPort(): string {
  const location = window.location;
  const host = location.host;
  const protocol = location.protocol;

  return `${protocol}//${host}`;
}

function keycloakJsonLocation(): string {
  return `${getProtocolHostNameAndPort()}/keycloak.json`;
}

class InternalSecurityService {
  private keycloakInstance!: KeycloakInstance;
  private keycloaProfile!: KeycloakProfile;

  async init(): Promise<void> {
    this.keycloakInstance = Keycloak(keycloakJsonLocation());
    this.registerEventHandlers();

    await this.keycloakInstance.init({
      onLoad: "login-required",
    });
  }

  private registerEventHandlers() {
    this.keycloakInstance.onAuthSuccess = () => {
      this.onAuthSuccess();
    };
    this.keycloakInstance.onAuthRefreshSuccess = () => {
      this.onAuthRefreshSuccess();
    };

    this.keycloakInstance.onTokenExpired = () => {
      this.onTokenExpired();
    };
  }

  private async onAuthSuccess() {
    await this.keycloakInstance.loadUserProfile().then((profile) => {
      this.keycloaProfile = profile;
    });
  }

  private onAuthRefreshSuccess(): void {
    /* noop */
  }

  async onTokenExpired() {
    await this.logout();
  }

  get profile(): KeycloakProfile {
    return this.profile;
  }

  get sessionValidInSeconds(): number {
    const instance = this.keycloakInstance;
    if (instance === undefined || instance === null) return 0;

    const token = instance.tokenParsed;
    if (token === undefined || token === null) return 0;

    if (token.exp === undefined || token.exp === null) return 0;

    let number = token.exp;

    const timeSkew = instance.timeSkew;
    if (timeSkew !== undefined && timeSkew !== null) {
      number += timeSkew;
    }

    return Math.round(number - new Date().getTime() / 1000);
  }

  async refreshSession() {
    await this.keycloakInstance.updateToken(MIN_VALIDITY);
  }

  async getToken(): Promise<string> {
    await this.keycloakInstance.updateToken(MIN_VALIDITY);
    return this.keycloakInstance.token!;
  }

  async logout(): Promise<void> {
    await this.keycloakInstance.logout({
      redirectUri: getProtocolHostNameAndPort(),
    });
  }
}

export const SecurityService = new InternalSecurityService();
