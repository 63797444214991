














import Vue from "vue";
import Component from "vue-class-component";

import HeaderComponent from "@/components/HeaderComponent.vue";
import NavigationComponent from "@/components/NavigationComponent.vue";
import ApplicationLoadingComponent from "@/components/ApplicationLoadingComponent.vue";
import UserNotificationComponent from "@/components/UserNotificationComponent.vue";

@Component({
  components: {
    HeaderComponent,
    NavigationComponent,
    ApplicationLoadingComponent,
    UserNotificationComponent,
  },
})
export default class App extends Vue {}
